import React from 'react';

const Banner = () => {
  return (
    <section className='banner'>
      <div className='container'>
        <span>Industrial Rope Access</span>
        
      </div>
    </section>
  );
};

export default Banner;
